import React, { useState, useEffect } from "react";
import { doc, getDocs, collection, deleteDoc } from "firebase/firestore";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import AddClassModal from "./AddClassModal";

const ClassTab = ({ db }) => {
  // Login States
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState([]);

  const [classModal, setClassModal] = useState(false);
  const [classes, setClasses] = useState([]);

  async function getClasses() {
    setClasses([]);
    const snapshot = await getDocs(collection(db, "classes"));

    snapshot.forEach((doc) => {
      setClasses((classes) => [...classes, { id: doc.id, data: doc.data() }]);
    });
  }

  useEffect(() => {
    getClasses();
  }, []);

  function editClass(name, date, description) {
    setName(name);
    setDate(date);
    setDescription(description);
    setClassModal(true);
  }

  function deleteDocument(docID) {
    deleteDoc(doc(db, "classes", docID))
      .then(() => {
        getClasses();
      })
      .catch((err) => console.log(err));
  }
  return (
    <div>
      <h1>Classes Dashboard</h1>
      <Button
        className="mb-3"
        variant="success"
        onClick={() => {
          setClassModal(true);
        }}>
        Add Class
      </Button>

      {/* Classes List */}
      {classes.map((classItem) => {
        return (
          <Card className="mb-3" key={classItem.id}>
            <Card.Header as="h4">
              {classItem.id}
              <Button className="float-end" variant="danger" onClick={() => deleteDocument(classItem.id)}>
                Delete
              </Button>
              <Button className="float-end me-1" variant="secondary" onClick={() => editClass(classItem.id, classItem.data.date, classItem.data.description)}>
                Edit
              </Button>
            </Card.Header>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">{classItem.data.date}</Card.Subtitle>
              {classItem.data.description.length > 0 &&
                classItem.data.description.map((line) => {
                  return (
                    <Card.Text key={line} className="mb-0">
                      {line}
                    </Card.Text>
                  );
                })}
            </Card.Body>
          </Card>
        );
      })}

      {/* Add Class Modal */}
      <AddClassModal classModal={classModal} setClassModal={setClassModal} db={db} getClasses={getClasses} name={name} setName={setName} date={date} setDate={setDate} description={description} setDescription={setDescription} />
    </div>
  );
};

export default ClassTab;
