import React from "react";
import "./members.css";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

const OperationalOfficer = ({ officerData }) => {
  let type = officerData.index % 2 === 0 ? "odd" : "even";
  if (type === "odd") {
    return (
      <Card border="0">
        <Card.Body>
          <Card.Title className="mb-4 officer-title-odd">{officerData.title}</Card.Title>
          <Row className="ms-3 me-3">
            <Col xs={12} md>
              <Card.Subtitle className="officer-name-odd">{officerData.name === "" ? "Vacant" : officerData.name}</Card.Subtitle>
              <Card.Text className="officer-desc">{officerData.desc}</Card.Text>
            </Col>
            <Col xs={12} md className="officer-photo-col-odd">
              <Image className="officer-photo" src={officerData.url} rounded fluid />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card border="0">
        <Card.Body>
          <Card.Title className="mb-4 officer-title-even">{officerData.title}</Card.Title>
          <Row className="ms-3 me-3">
            <Col xs={12} md className="officer-photo-col-even">
              <Image className="officer-photo" src={officerData.url === undefined ? "" : officerData.url} rounded fluid />
            </Col>
            <Col xs={12} md>
              <Card.Subtitle className="officer-name-even">{officerData.name === "" ? "Vacant" : officerData.name}</Card.Subtitle>
              <Card.Text className="officer-desc" style={{ textAlign: "right" }}>
                {officerData.name === "" && officerData.desc === "" ? "This position is currently vacant" : officerData.desc}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
};

export default OperationalOfficer;
