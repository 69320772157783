import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useEffect, useState } from "react";
import { auth, onAuthStateChanged, signInWithEmailAndPassword, signOut, db, storage } from "./firebase";

//Components
import Footer from "./components/Footer/Footer";
import NavbarComponent from "./components/Navbar/Navbar";

//Views
import Home from "./views/Home";
import Mission from "./views/Mission";
import Apparatus from "./views/Apparatus";
import Members from "./views/Members";
import Classes from "./views/Classes";
import Renting from "./views/Renting";
import Join from "./views/Join";
import Admin from "./views/Admin";
import LoginModal from "./components/LoginModal/LoginModal";

const App = () => {
  // Navigation and Modal Control
  const [page, setPage] = useState(sessionStorage.getItem("currentPage") === null ? "/" : sessionStorage.getItem("currentPage"));
  const [showModal, setShowModal] = useState(false);

  // User Login and State
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();

  const [user, setUser] = useState();
  const [authenticated, setAuthentication] = useState(false);

  function login() {
    signInWithEmailAndPassword(auth, email, pass)
      .then((user) => setUser(user))
      .catch((err) => {
        let reason = err.code;
        if (reason === "auth/invalid-email" || reason === "auth/user-not-found") {
          alert("Invalid email");
        } else {
          alert("Invalid password");
        }
      });
  }

  function logout() {
    signOut(auth).then(() => {
      setAuthentication(false);
      setUser(null);
      setPage("/");
    });
  }

  useEffect(() => {
    page != null && sessionStorage.setItem("currentPage", page);

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setAuthentication(true);
        setShowModal(false);
      } else {
        page === "/admin" && setPage("/");
      }
    });
  }, [page, authenticated]);

  return (
    <div>
      <NavbarComponent page={page} setPage={setPage} setShowModal={setShowModal} authenticated={authenticated} logout={logout} />

      {/* Page Navigation */}
      {page === "/" && <Home />}
      {page === "/mission" && <Mission />}
      {page === "/apparatus" && <Apparatus db={db} />}
      {page === "/members" && <Members db={db} />}
      {page === "/classes" && <Classes db={db} />}
      {page === "/renting" && <Renting />}
      {page === "/join" && <Join />}
      {page === "/admin" && <Admin db={db} storage={storage} />}

      {/* Login Modal */}
      <LoginModal showModal={showModal} setShowModal={setShowModal} setEmail={setEmail} setPass={setPass} login={login} />

      {/* <Footer /> */}
    </div>
  );
};

export default App;
