import React, { useEffect, useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Bootstrap Components
import Accordion from "react-bootstrap/Accordion";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

//firebase
import { doc, setDoc } from "firebase/firestore";

const Officer = ({ index, title, data, db }) => {
  const [officerImg, setOfficerImg] = useState();
  const [officerName, setOfficerName] = useState(data !== undefined ? data.name : "");
  const [officerDesc, setOfficerDesc] = useState(data !== undefined ? data.desc : "");

  const storage = getStorage();
  const storageRef = ref(storage, `officers/${title}`);

  function getImageURL() {
    getDownloadURL(storageRef)
      .then((url) => {
        setOfficerImg(url);
      })
      .catch((err) => console.log());
  }

  useEffect(() => {
    getImageURL();
  }, []);

  function saveData() {
    if (typeof officerImg === "object") {
      uploadBytes(storageRef, officerImg).then((data) => {
        console.log("Image Uploaded");
        getImageURL();
      });
    }

    setDoc(doc(db, "officers", title), {
      name: officerName !== undefined ? officerName : "",
      desc: officerDesc !== undefined ? officerDesc : "",
    }).then(() => console.log("Data Uploaded"));
  }

  return (
    <Accordion.Item eventKey={index}>
      <Accordion.Header>
        <h4 className="mb-0">{title}</h4>
      </Accordion.Header>
      <Accordion.Body>
        <Row className="mb-2">
          <Col xs={6}>
            <Row>
              <Col className="mb-2" xs={12}>
                <InputGroup>
                  <InputGroup.Text>Name</InputGroup.Text>
                  <Form.Control value={officerName !== undefined ? officerName : ""} onChange={(e) => setOfficerName(e.target.value)} type="text" placeholder="First and Last Name"></Form.Control>
                </InputGroup>
              </Col>
              <Col>
                <Form.Control
                  value={officerDesc}
                  onChange={(e) => setOfficerDesc(e.target.value)}
                  maxLength={300}
                  as="textarea"
                  rows={5}
                  placeholder="Put a description of the person here (achivements, how long they've been with the agency, etc)"></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col>
            <InputGroup>
              <Form.Control onChange={(e) => setOfficerImg(e.target.files[0])} type="file"></Form.Control>
            </InputGroup>
          </Col>
          <Col xs={2}>
            <Image src={officerImg} fluid />
            <button>Clear</button>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col></Col>
        </Row>
        <Row>
          <Col className="d-grid" lg={{ span: 2, offset: 10 }}>
            <Button onClick={() => saveData()}>Save</Button>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default Officer;
