import { getDoc, doc, setDoc } from "firebase/firestore";

function moveApparatus(db, unitData, direction, getUnits) {
  const unitId = unitData.id;
  const unitType = unitData.type.toLowerCase();

  getDoc(doc(db, "unitOrder", unitType)).then((snapshot) => {
    let unitArr = snapshot.data()[unitType];
    let index = unitArr.indexOf(unitId);

    if (direction === "up") {
      if (index !== 0) {
        unitArr.splice(index, 1);
        unitArr.splice(index - 1, 0, unitData.id);
      }
    }

    if (direction === "down") {
      if (index !== unitArr.length) {
        unitArr.splice(index, 1);
        unitArr.splice(index + 1, 0, unitData.id);
      }
    }

    setDoc(doc(db, "unitOrder", unitType), {
      [unitType]: unitArr,
    }).then(() => getUnits());
  });
}

export default moveApparatus;
