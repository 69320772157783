import React from "react";
import posting from "../assets/GRS Chief Posting.png";
import details from "../assets/GRS Rescue Chief Job Description.pdf";

const Home = () => {
  return (
    <div className="m-4 mb-5">
      <h1 className="text-muted" style={{ textAlign: "center" }}>
        No Announcements
      </h1>
    </div>
  );
};

export default Home;
