import React, { useEffect, useState } from "react";
import { getDocs, getDoc, collection, doc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import Apparatus from "./Apparatus";
import AddApparatusModal from "./AddApparatusModal";

//bootstrap
import Button from "react-bootstrap/Button";

const ApparatusTab = ({ db }) => {
  const [apparatusModal, setApparatusModal] = useState(false);
  const [apparatusArr, setApparatusArr] = useState([]);
  const [responseArr, setResponseArr] = useState([]);

  function getURL(location) {
    const storage = getStorage();
    const storageRef = ref(storage, `apparatus/${location}`);

    return getDownloadURL(storageRef);
  }

  function getUnits() {
    setApparatusArr([]);
    setResponseArr([]);
    getDocs(collection(db, "unitOrder")).then((data) => {
      data.forEach(async (unitOrder) => {
        let unitArr = unitOrder.data()[unitOrder.id];

        unitArr.forEach((unit) => {
          getDoc(doc(db, "apparatus", unit)).then((unitData) => {
            getURL(unitData.id).then((url) => {
              if (unitOrder.id === "ambulance") {
                setApparatusArr((apparatusArr) => [...apparatusArr, { id: unitData.id, coverPhoto: url, ...unitData.data() }]);
              } else {
                setResponseArr((responseArr) => [...responseArr, { id: unitData.id, coverPhoto: url, ...unitData.data() }]);
              }
            });
          });
        });
      });
    });
  }

  useEffect(() => {
    getUnits();
  }, []);

  return (
    <div>
      <Button className="mb-3" onClick={() => setApparatusModal(true)}>
        Add Apparatus
      </Button>
      <h2>Apparatus</h2>

      {apparatusArr.map((apparatus, index) => {
        return <Apparatus db={db} key={apparatus.id} unitData={apparatus} getUnits={getUnits} />;
      })}

      <h2>Response</h2>
      {responseArr.map((response, index) => {
        return <Apparatus db={db} key={response.id} unitData={response} getUnits={getUnits} />;
      })}

      <AddApparatusModal db={db} apparatusModal={apparatusModal} setApparatusModal={setApparatusModal} getUnits={getUnits} />
    </div>
  );
};

export default ApparatusTab;
