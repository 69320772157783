import React from "react";
import Officers from "./Officers/Officers";
import Members from "./MemberTable/Members";

const MembersTab = ({ db }) => {
  return (
    <div>
      <Officers db={db} />

      <h1>Members</h1>
      <hr className="mt-0" />
      <Members db={db} />
    </div>
  );
};

export default MembersTab;
