import React, { useState } from "react";
import "./navbar.css";
import logo from "../../assets/logo.gif";

const NavbarComponent = ({ page, setPage, setShowModal, authenticated, logout }) => {
  const [menu, setMenu] = useState(false);

  function changePage(dir) {
    setPage(dir);
    setMenu(false);
  }

  return (
    /* prettier-ignore */
    <div>
      <div className="custom-navbar-header">
        <img className="ms-3" src={logo}/>
        <h1>Grottoes Rescue Squad</h1>
      </div>
      <ul className="custom-navbar">
      <li className={`show-${menu} ${page === "/" && "active"}`} onClick={() => {changePage("/")}}>News</li>
      <li className={`show-${menu} ${page === "/mission" && "active"}`} onClick={() => {changePage("/mission")}}>Our Mission</li>
      <li className={`show-${menu} ${page === "/apparatus" && "active"}`} onClick={() => {changePage("/apparatus")}}>Apparatus</li>
      <li className={`show-${menu} ${page === "/members" && "active"}`} onClick={() => {changePage("/members")}}>Members</li>
      <li className={`show-${menu} ${page === "/classes" && "active"}`} onClick={() => {changePage("/classes")}}>Classes</li>
      <li className={`show-${menu} ${page === "/renting" && "active"}`} onClick={() => {changePage("/renting")}}>Renting</li>
      <li className={`show-${menu} ${page === "/join" && "active"}`} onClick={() => {changePage("/join")}}>Join Us</li>
      {(authenticated) && <li className={`show-${menu} ${page === "/admin" && "active"}`} onClick={() => {changePage("/admin")}}>Admin</li>}
      {(authenticated) ? <li className="align-right" onClick={() => {logout()}}>Logout</li> : <li className="align-right" onClick={() => {setShowModal(true)}}>Login</li>}

      <li onClick={() => (menu) ? setMenu(false) : setMenu(true)} className={`hamburger-menu-${menu === false ? "close" : "open"} align-right`}><svg viewBox="0 0 80 80" width="25" height="20">
        <rect style={{fill: "white"}} x="-10" y="-15" width="120" height="15"></rect>
        <rect style={{fill: "white"}} x="-10" y="25" width="120" height="15"></rect>
        <rect style={{fill: "white"}} x="-10" y="65" width="120" height="15"></rect>
      </svg></li>
    </ul>
    </div>
  );
};

export default NavbarComponent;
