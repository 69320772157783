import React, { useState } from "react";
import { doc, setDoc, deleteDoc } from "firebase/firestore";

//bootstrap
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const Member = ({ memberData, db, getMembers }) => {
  const [memberName, setMemberName] = useState(memberData.name);
  const [memberLevel, setMemberLevel] = useState(memberData.level);
  const [memberStatus, setMemberStatus] = useState(memberData.status);
  const [lifetimeStatus, setLifetimeStatus] = useState(memberData.lifetimeStatus);
  const [change, setChange] = useState(false);

  function saveChanges() {
    setDoc(doc(db, "members", memberData.id), {
      name: memberName,
      level: memberLevel,
      status: memberStatus,
      lifetimeStatus: memberStatus !== "Lifetime" ? false : lifetimeStatus,
    }).then(() => setChange(false));
  }

  function deleteMember() {
    let answer = prompt(`Please enter "${memberData.name}" and then press ok`);

    if (answer === memberName) {
      deleteDoc(doc(db, "members", memberData.id))
        .then(() => {
          alert(memberData.name + " deleted");
          getMembers();
        })
        .catch((err) => {
          alert("Member could not be deleted");
          console.log(err);
        });
    } else {
      alert("Member name is incorrect");
    }
  }

  return (
    <tr>
      <td>
        <Form.Control
          type="text"
          value={memberName}
          onChange={(e) => {
            setMemberName(e.target.value);
            setChange(true);
          }}></Form.Control>
      </td>
      <td>
        <Form.Select
          value={memberLevel}
          onChange={(e) => {
            setMemberLevel(e.target.value);
            setChange(true);
          }}>
          <option></option>
          <option>Student</option>
          <option>CPR</option>
          <option>Driver</option>
          <option>EMT</option>
          <option>Advanced EMT</option>
          <option>Intermediate</option>
          <option>Paramedic</option>
        </Form.Select>
      </td>
      <td style={{ textAlign: "right" }}>
        <InputGroup>
          <Form.Select
            value={memberStatus}
            onChange={(e) => {
              setMemberStatus(e.target.value);
              setChange(true);
            }}>
            <option>Associate</option>
            <option>Active</option>
            <option>Junior</option>
            <option>Non-EMS</option>
            <option>Lifetime</option>
            <option>Memorial</option>
          </Form.Select>
          {memberStatus === "Lifetime" && (
            <InputGroup.Checkbox
              defaultChecked={lifetimeStatus}
              onChange={(e) => {
                setLifetimeStatus(e.target.checked);
                setChange(true);
              }}></InputGroup.Checkbox>
          )}
        </InputGroup>
      </td>
      <td>
        <ButtonGroup className="float-end">
          <Button variant="danger" onClick={() => deleteMember()}>
            Delete
          </Button>
          <Button disabled={change === false ? true : false} variant="success" onClick={() => saveChanges()}>
            Save
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
};

export default Member;
