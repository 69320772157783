import React, { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import "./apparatus.css";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

const Ambulance = ({ unitData, db, index }) => {
  const [unitInfo, setUnitInfo] = useState(null);
  const [unitPhoto, setUnitPhoto] = useState(null);

  useEffect(() => {
    getDoc(doc(db, "apparatus", unitData))
      .then((snap) => {
        setUnitInfo({ ...snap.data(), id: unitData });
        return { ...snap.data(), id: unitData };
      })
      .then((data) => {
        const storage = getStorage();
        const storageRef = ref(storage, `apparatus/${data.id}`);

        getDownloadURL(storageRef).then((url) => setUnitPhoto(url));
      });
  }, []);

  if (unitInfo !== null) {
    return (
      <Card border="0" style={{ backgroundColor: index % 2 !== 0 && "#e4e4e7" }} className="mb-4">
        <Card.Body className="m-3">
          <Row>
            <Col xs={12} lg={6}>
              <Card.Title>
                <h2>{unitInfo.name}</h2>
              </Card.Title>
              <Card.Text className="mb-3">{unitInfo.desc}</Card.Text>
            </Col>
            <Col className="ambulance-photo" xs={12} lg={6}>
              <Image src={unitPhoto} rounded />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
};

export default Ambulance;
