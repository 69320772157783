import React from "react";
import training1 from "../assets/renting/training1.jpg";
import training2 from "../assets/renting/training2.jpg";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Renting = () => {
  return (
    <Container className="m-lg-5 mt-3 m-sm-2">
      <Row>
        <Col xs={12} md={6}>
          <div>
            <b>Amenities: (Cost: $50)</b>
          </div>
          <ul>
            <li>Full sized kitchen with commerical fridge and freezer</li>
            <li>Ice maker</li>
            <li>Chairs and tables for you to set the room how you would like it</li>
          </ul>
          <div style={{ textAlign: "center" }}>
            <p>
              <i>If you have any questions please fill out the form and someone will contact you.</i>
            </p>
            <p>
              Grottoes Rescue Squad does <b>NOT</b> have an ABC license. Therefore no alcohol is permitted on the premesis
            </p>
          </div>
          <img className="mb-2" style={{ width: "100%" }} src={training1} />
          <img style={{ width: "100%" }} src={training2} />
        </Col>
        <Col>
          <iframe title="rentingForm" src="https://docs.google.com/forms/d/e/1FAIpQLSfnTDFzi1qaQxYK8n59NTc3JnN67hDT4y-gOG78RTNWLtFt6g/viewform?embedded=true" width="100%" height="1400" frameborder="0" marginheight="0" marginwidth="0">
            Loading…
          </iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default Renting;
