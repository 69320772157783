import React from "react";
import "./members.css";

import Card from "react-bootstrap/Card";

const AdminOfficer = ({ officerData }) => {
  return (
    <Card className="admin-card">
      <Card.Img variant="top" className="admin-photo" src={officerData.url} />
      <Card.Body>
        <Card.Title>{officerData.title === "Board Member 1" || officerData.title === "Board Member 2" ? "Board Member" : officerData.title}</Card.Title>
        <Card.Subtitle className="text-muted mb-3">{officerData.name === undefined ? "Vacant" : officerData.name}</Card.Subtitle>
        <Card.Text>{officerData.name === "" && officerData.desc === "" ? "This position is currently vacant" : officerData.desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default AdminOfficer;
