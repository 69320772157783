import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Officer from "./Officer";

import { doc, getDoc } from "firebase/firestore";

const Officers = ({ db }) => {
  const [officers, setOfficers] = useState([]);
  const [admins, setAdmin] = useState([]);

  const officerTitles = ["Chief", "Deputy Chief", "Captain", "Lieutenant", "Training Captain"];
  const adminTitles = ["President", "Vice President", "Secretary", "Treasurer", "Board Member 1", "Board Member 2"];

  useEffect(() => {
    officerTitles.map(async (officer, index) => {
      const ref = doc(db, "officers", officer);
      await getDoc(ref).then((data) => {
        setOfficers((officers) => [...officers, { index: index, title: officer, data: data.data() !== undefined && data.data() }]);
      });
    });

    adminTitles.map(async (admin, index) => {
      const ref = doc(db, "officers", admin);
      await getDoc(ref).then((data) => {
        setAdmin((admins) => [...admins, { index: index, title: admin, data: data.data() !== undefined && data.data() }]);
      });
    });
  }, []);

  return (
    <div className="mb-4">
      <h1>Officers</h1>
      <hr className="mt-0" />

      <h2>Operational</h2>
      <Accordion className="mb-3">
        {officers.map((officer) => (
          <Officer db={db} key={officer.title} index={officer.index} title={officer.title} data={officer.data} />
        ))}
      </Accordion>

      <h2>Administrative</h2>
      <Accordion className="mb-3">
        {admins.map((admin) => (
          <Officer db={db} key={admin.title} index={admin.index} title={admin.title} data={admin.data} />
        ))}
      </Accordion>
    </div>
  );
};

export default Officers;
