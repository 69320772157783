import React from "react";

const Mission = () => {
  return (
    <div className="m-4 mb-5">
      <h1>Thanks for visiting the Grottoes Rescue Squad Website</h1>
      <h2>Our Mission</h2>
      <ul>
        <li>To provide pre-hospital emergency care and rescue services to the citizens of Augusta and Rockingham counties and the surrounding areas.</li>
      </ul>
      <h2>Our Purpose</h2>
      <ul>
        <li>In pursuit of our mission, Grottoes Rescue Squad aspires to the following</li>
        <li>We recognize and affirm the worth and value of each and all persons</li>
        <li>We treat those we serve with skill, respect, and kindness</li>
        <li>We act with integrity, honesty, and fairness in the way we provide our services</li>
        <li>We trust our colleagues as valuable members of our healthcare team and we pledge to treat one another with loyalty, respect, and dignity</li>
        <li>We enter into and maintain a commitment with this organization-with emphasis on "commitment"-fully knowning and rocgnizing that this organization and our communities rely upon our diligence of service to properly fulfill our mission</li>
      </ul>
    </div>
  );
};

export default Mission;
