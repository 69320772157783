import { doc, deleteDoc, getDoc, setDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

function deleteApparatus(db, unitData, getUnits) {
  let answer = prompt(`Please enter "${unitData.name}" to delete`);

  if (answer === unitData.name) {
    deleteDoc(doc(db, "apparatus", unitData.id)).then(() => {
      const storage = getStorage();
      const storageRef = ref(storage, `apparatus/${unitData.id}`);

      deleteObject(storageRef)
        .then(async () => {
          alert("Apparatus successfully delete");
          //need to pull unit order and slice the unitid from array, then write back to firestore
          await getDoc(doc(db, "unitOrder", unitData.type.toLowerCase())).then((unitObj) => {
            let unitArr = unitObj.data()[unitData.type.toLowerCase()];

            let removalIndex = unitArr.indexOf(unitData.id);
            unitArr.splice(removalIndex, 1);

            setDoc(doc(db, "unitOrder", unitData.type.toLowerCase()), {
              [unitData.type.toLowerCase()]: unitArr,
            }).then(() => {
              getUnits();
            });
          });
        })
        .catch((err) => {
          alert(err, "Unable to delete apparatus");
        });
    });
  } else {
    alert("Unit name does not match. Apparatus not deleted");
  }
}

export default deleteApparatus;
