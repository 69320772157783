// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, connectAuthEmulator, signOut } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDQpB89dw721sMRlHYChSCgTljG_6otqJs",
  authDomain: "rescue20-7117d.firebaseapp.com",
  databaseURL: "https://rescue20-7117d-default-rtdb.firebaseio.com",
  projectId: "rescue20-7117d",
  storageBucket: "rescue20-7117d.appspot.com",
  messagingSenderId: "509211760516",
  appId: "1:509211760516:web:41c0ea2d7aaa1a19172fd1",
  measurementId: "G-WDNRF5PF0W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

//Connect to emulator

// connectAuthEmulator(auth, "http://127.0.0.1:9099");
// connectFirestoreEmulator(db, "127.0.0.1", 8080);
// connectStorageEmulator(storage, "127.0.0.1", 9199);

export { auth, onAuthStateChanged, signInWithEmailAndPassword, signOut, db, storage };
