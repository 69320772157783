import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import AddMemberModal from "./AddMemberModal";
import Member from "./Member";

//bootstrap
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

const Members = ({ db }) => {
  const [members, setMembers] = useState([]);
  const [memberModal, setMemberModal] = useState(false);

  function getMembers() {
    getDocs(collection(db, "members"))
      .then((data) => {
        var memberArr = [];
        data.forEach((member) => {
          memberArr.push({
            id: member.id,
            name: member.data().name,
            level: member.data().level,
            status: member.data().status,
            lifetimeStatus: member.data().lifetimeStatus,
          });
        });
        return memberArr;
      })
      .then((unsorted) => {
        const sortedMemberArray = unsorted.sort((a, b) => {
          let aName = a.name.split(" ");
          let bName = b.name.split(" ");
          return aName[aName.length - 1] > bName[bName.length - 1] ? 1 : bName[bName.length - 1] > aName[aName.length - 1] ? -1 : 0;
        });
        setMembers(sortedMemberArray);
      });
  }

  useEffect(() => {
    getMembers();
  }, []);

  return (
    <div>
      <Button className="mb-2" onClick={() => setMemberModal(true)}>
        Add Member
      </Button>

      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Level</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member) => {
            return <Member key={member.id} db={db} memberData={member} getMembers={getMembers} />;
          })}
        </tbody>
      </Table>

      {memberModal && <AddMemberModal db={db} setMemberModal={setMemberModal} getMembers={getMembers} />}
    </div>
  );
};

export default Members;
