import React from "react";

// Components
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import ClassTab from "../components/Admin/Classes/ClassTab";
import MembersTab from "../components/Admin/Members/MembersTab";
import ApparatusTab from "../components/Admin/Apparatus/ApparatusTab";
import BlogTab from "../components/Admin/Blog/BlogTab";

const Admin = ({ db, storage }) => {
  return (
    <div className="ms-3 me-3 mb-5 mt-4">
      <Tabs>
        <Tab eventKey="Blog" title="Blog" className="m-3">
          <BlogTab></BlogTab>
        </Tab>
        <Tab eventKey="apparatus" title="Apparatus" className="m-3">
          <ApparatusTab db={db} storage={storage} />
        </Tab>

        <Tab eventKey="classes" title="Classes" className="m-3">
          {/* Classes */}
          <ClassTab db={db} />
        </Tab>

        <Tab eventKey="members" title="Members" className="m-3">
          {/* Members */}
          <MembersTab storage={storage} db={db} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Admin;
