import "./classes.css";

import React from "react";
import Col from "react-bootstrap/Col";
import ReactMarkdown from "react-markdown";
import Card from "react-bootstrap/Card";

const ClassCard = ({ classItem }) => {
  return (
    <Col>
      <Card>
        <Card.Header as="h1">
          {classItem.id}
          {classItem.data.date !== "" && (
            <Card.Subtitle className="mb-2 mt-0 text-muted">
              <b>{classItem.data.date}</b>
            </Card.Subtitle>
          )}
        </Card.Header>
        <Card.Body>
          {classItem.data.description.length > 0 &&
            classItem.data.description.map((lineItem) => {
              if (lineItem === "") {
                return <br></br>;
              } else {
                return <ReactMarkdown className="markdown-items">{lineItem}</ReactMarkdown>;
              }
            })}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ClassCard;
