import React from "react";

//Components
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";

const LoginModal = ({ showModal, setShowModal, setEmail, setPass, login }) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FloatingLabel className="mb-2" label="Email">
          <Form.Control onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email address"></Form.Control>
        </FloatingLabel>

        <FloatingLabel className="mb-2" label="Password">
          <Form.Control onChange={(e) => setPass(e.target.value)} type="password" placeholder="Password"></Form.Control>
        </FloatingLabel>

        <div className="d-grid gap-2">
          <Button onClick={() => login()}>Login</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
