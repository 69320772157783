import React, { useState } from "react";
import deleteApparatus from "./deleteApparatus";
import moveApparatus from "./moveApparatus";
import updateApparatus from "./updateApparatus";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

const Apparatus = ({ unitData, db, getUnits }) => {
  const [change, setChange] = useState(false);
  const [unitName, setUnitName] = useState(unitData.name);
  const [unitType, setUnitType] = useState(unitData.type);
  const [unitDesc, setUnitDesc] = useState(unitData.desc);
  const [unitPhoto, setUnitPhoto] = useState(unitData.coverPhoto);

  return (
    <Card className="mb-3">
      <Card.Header>
        <div></div>
        <Row>
          <Col xs={12} md={2}>
            <h4>{unitData.name} </h4>
          </Col>
          <Col>
            <Button className="me-2" variant="secondary" onClick={() => moveApparatus(db, unitData, "up", getUnits)}>
              Move Up
            </Button>
            <Button variant="secondary" onClick={() => moveApparatus(db, unitData, "down", getUnits)}>
              Move Down
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col className="mb-2" xs={12} lg={4}>
            <Form.Control
              placeholder="Unit name"
              value={unitName}
              onChange={(e) => {
                setUnitName(e.target.value);
                setChange(true);
              }}></Form.Control>
          </Col>
          <Col>
            <Form.Select value={unitType} disabled>
              <option></option>
              <option>Ambulance</option>
              <option>Response</option>
            </Form.Select>
          </Col>
          <Col className="mb-2" lg={4}>
            <Form.Control
              type="file"
              onChange={(e) => {
                setUnitPhoto(e.target.files[0]);
                setChange(true);
              }}></Form.Control>
          </Col>
          <Col className="mb-2" lg={2}>
            <img style={{ width: "100%" }} src={unitPhoto}></img>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Unit description"
              value={unitDesc}
              onChange={(e) => {
                setUnitDesc(e.target.value);
                setChange(true);
              }}></Form.Control>
          </Col>
        </Row>
        <Row>
          <Col xs={{ offset: 8, span: 2 }}>
            <div className="d-grid">
              <Button disabled={!change} variant="success" onClick={() => updateApparatus(db, { id: unitData.id, name: unitName, type: unitType, desc: unitDesc, coverPhoto: unitPhoto }, getUnits)}>
                Save
              </Button>
            </div>
          </Col>
          <Col xs={{ span: 2 }}>
            <div className="d-grid">
              <Button onClick={() => deleteApparatus(db, unitData, getUnits)} variant="danger">
                Delete
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Apparatus;
