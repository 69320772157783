import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const cardCustomHeader = {
  backgroundColor: "rgb(15, 111, 84)",
  color: "white",
};

const Join = () => {
  return (
    <Container className="m-lg-4 m-sm-2 mt-3">
      <Row>
        <Col xs={12} lg={6}>
          <Row>
            <Col xs={12}>
              <p>
                The Grottoes Rescue Squad is continually searching for compassionate persons for membership with our agency. If you have the drive and desire to help and serve your community in need, we invite you to fill out an application. Our
                agency welcomes trained and untrained members. We are a volunteer organization that provides basic and advanced emergency medical care for the citizens of the town of Grottoes, as well as Augusta and Rockingham counties. The community
                depends on us daily to respond in their time of need. The responsibility is a great one, and it takes time, skill, desire and dedication to perform under the adverse and stressful situations in the field.
              </p>
              <p>Grottoes Rescue Squad offers three different types of membership. They are listed below:</p>
            </Col>
          </Row>
          <Row>
            {/* Active Card */}
            <Col xs={12} sm={12} lg={6} className="mb-3">
              <Card>
                <Card.Header style={cardCustomHeader}>
                  <Card.Title className="mb-0" style={{ fontWeight: "bold" }}>
                    Active - 32 Hours
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul className="mb-0">
                      <li>Required to run 32 hours a month</li>
                      <li>Attend monthly business meetings</li>
                      <li>Attend monthly training</li>
                      <li>You can vote and hold offices within the organization</li>
                      <ul>
                        <li>This is only after completing probation</li>
                      </ul>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Associate Cards */}
            <Col xs={12} lg={6} className="mb-3">
              <Card>
                <Card.Header style={cardCustomHeader}>
                  <Card.Title className="mb-0" style={{ fontWeight: "bold" }}>
                    Associate - 20 hours
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul className="mb-0">
                      <li>Required to run 20 hours a month</li>
                      <li>Attend monthly business meetings</li>
                      <li>Attend monthly training</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Non-EMS Cards */}
            <Col xs={12} lg={6} className="mb-3">
              <Card>
                <Card.Header style={cardCustomHeader}>
                  <Card.Title className="mb-0" style={{ fontWeight: "bold" }}>
                    Non-EMS
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul className="mb-0">
                      <li>Required to run 20 hours a month</li>
                      <li>Attend monthly business meetings</li>
                      <li>Attend monthly training</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col>
          <iframe title="joinForm" src="https://docs.google.com/forms/d/e/1FAIpQLScO2pj2On_kgAfCAbyDaW4RpNm1VeLsPksE3vuU13vkZj9LBQ/viewform?embedded=true" width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0">
            Loading…
          </iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default Join;
