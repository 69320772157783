import { doc, setDoc } from "firebase/firestore";
import { ref, getStorage, uploadBytes } from "firebase/storage";

function updateApparatus(db, unitData, getUnits) {
  if (typeof unitData.coverPhoto === "object") {
    const storage = getStorage();
    const storageRef = ref(storage, `apparatus/${unitData.id}`);

    uploadBytes(storageRef, unitData.coverPhoto);
  }

  setDoc(doc(db, "apparatus", unitData.id), {
    name: unitData.name,
    desc: unitData.desc,
    type: unitData.type,
  }).then(() => {
    alert("Apparatus updated");
    getUnits();
  });
}

export default updateApparatus;
