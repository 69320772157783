import React, { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import Ambulance from "../components/Apparatus/Ambulance";
import Response from "../components/Apparatus/Response";

//bootstrap
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Apparatus = ({ db }) => {
  const [ambulances, setAmbulances] = useState([]);
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    getDoc(doc(db, "unitOrder", "ambulance")).then((ambulanceData) => {
      if (ambulanceData.data() !== undefined) {
        setAmbulances(ambulanceData.data()["ambulance"]);
      }
    });

    getDoc(doc(db, "unitOrder", "response")).then((responseData) => {
      if (responseData.data() !== undefined) {
        setResponses(responseData.data()["response"]);
      }
    });
  }, []);

  return (
    <div className=" mt-5">
      {/* Apparatus */}
      <Container>
        {ambulances.map((unit, index) => {
          return (
            <Row key={unit}>
              <Col xs={12}>
                <Ambulance db={db} unitData={unit} index={index} />
              </Col>
            </Row>
          );
        })}
      </Container>

      {/* Response */}
      <div style={{ backgroundColor: "#475569" }}>
        <Container className="p-4">
          <Row className="mb-3">
            {responses.map((response) => {
              return (
                <Col xs={12} md key={response}>
                  <Response unitData={response} db={db} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Apparatus;
