import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";

const AddMemberModal = ({ db, setMemberModal, getMembers }) => {
  const [memberName, setMemberName] = useState();
  const [memberStatus, setMemberStatus] = useState("Associate");
  const [memberLevel, setMemberLevel] = useState();
  const [lifetimeStatus, setLifetimeStatus] = useState(false);

  async function addMember() {
    addDoc(collection(db, "members"), {
      name: memberName,
      level: memberLevel === undefined ? "" : memberLevel,
      status: memberStatus,
      lifetimeStatus: memberStatus !== "Lifetime" ? false : lifetimeStatus,
    }).then(() => {
      setMemberName("");
      setMemberLevel("Associate");
      setMemberStatus("EMT");
      setMemberModal(false);

      getMembers();
    });
  }

  return (
    <Modal show={true} onHide={() => setMemberModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add Member</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xs={12} className="mb-3">
            <InputGroup>
              <InputGroup.Text>Name</InputGroup.Text>
              <Form.Control required type="text" onChange={(e) => setMemberName(e.target.value)}></Form.Control>
            </InputGroup>
          </Col>
          <Col xs={12} className="mb-3">
            <InputGroup>
              <InputGroup.Text>Status</InputGroup.Text>
              <Form.Select onChange={(e) => setMemberStatus(e.target.value)}>
                <option>Associate</option>
                <option>Active</option>
                <option>Junior</option>
                <option>Non-EMS</option>
                <option>Lifetime</option>
                <option>Memorial</option>
              </Form.Select>
              {memberStatus === "Lifetime" && <InputGroup.Checkbox onChange={(e) => setLifetimeStatus(e.target.checked)}></InputGroup.Checkbox>}
            </InputGroup>
          </Col>
          {memberStatus !== "Memorial" && (
            <Col xs={12}>
              <InputGroup>
                <InputGroup.Text>Level</InputGroup.Text>
                <Form.Select onChange={(e) => setMemberLevel(e.target.value)}>
                  <option></option>
                  <option>Student</option>
                  <option>CPR</option>
                  <option>Driver</option>
                  <option>EMT</option>
                  <option>Advanced EMT</option>
                  <option>Intermediate</option>
                  <option>Paramedic</option>
                </Form.Select>
              </InputGroup>
            </Col>
          )}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => addMember()}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMemberModal;
