import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";

import Row from "react-bootstrap/Row";
import ClassCard from "../components/Classes/ClassCard";

const Classes = ({ db }) => {
  const [classes, setClasses] = useState([]);

  async function getClasses() {
    setClasses([]);
    const snapshot = await getDocs(collection(db, "classes"));

    snapshot.forEach((item) => {
      setClasses((classes) => [...classes, { id: item.id, data: item.data() }]);
    });
  }

  useEffect(() => {
    getClasses();
  }, []);

  if (classes.length !== 0) {
    return (
      <Row xs={1} sm={2} md={3} className="m-3">
        {classes.map((classItem) => {
          return <ClassCard classItem={classItem} />;
        })}
      </Row>
    );
  } else {
    return (
      <h1 className="m-3 mt-5 text-muted" style={{ textAlign: "center" }}>
        There are no upcoming classes
      </h1>
    );
  }
};

export default Classes;
