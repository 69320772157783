import React, { useState } from "react";
import { collection, addDoc, setDoc, doc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const AddApparatusModal = ({ apparatusModal, setApparatusModal, db, getUnits }) => {
  const [unitName, setUnitName] = useState();
  const [unitType, setUnitType] = useState();
  const [unitDesc, setUnitDesc] = useState();
  const [unitImg, setUnitImg] = useState();

  function saveUnit() {
    if (unitName !== undefined && unitType !== undefined && unitDesc !== undefined && unitImg !== undefined) {
      addDoc(collection(db, "apparatus"), {
        name: unitName,
        type: unitType,
        desc: unitDesc,
      }).then((data) => {
        var unitId = data.id;
        const storage = getStorage();
        const storageRef = ref(storage, `apparatus/${data.id}`);

        uploadBytes(storageRef, unitImg).then(() => {
          alert("Apparatus added");
          getUnits();
          setUnitName();
          setUnitType();
          setUnitDesc();
          setUnitImg();
          setApparatusModal(false);
        });

        //place unit id in unit order array
        if (unitType === "Ambulance") {
          getDoc(doc(db, "unitOrder", "ambulance")).then((snapshot) => {
            const data = snapshot.data();

            setDoc(doc(db, "unitOrder", "ambulance"), {
              ambulance: data === undefined ? [unitId] : [...data.ambulance, unitId],
            });
          });
        } else {
          getDoc(doc(db, "unitOrder", "response")).then((snapshot) => {
            const data = snapshot.data();

            setDoc(doc(db, "unitOrder", "response"), {
              response: data === undefined ? [unitId] : [...data.response, unitId],
            });
          });
        }
      });
    } else {
      alert("Missing information");
    }
  }

  return (
    <Modal show={apparatusModal} onHide={() => setApparatusModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add Apparatus</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col xs={12} md={4}>
            <Form.Control placeholder="Unit Name" onChange={(e) => setUnitName(e.target.value)}></Form.Control>
          </Col>
          <Col>
            <Form.Control type="file" onChange={(e) => setUnitImg(e.target.files[0])}></Form.Control>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Select onChange={(e) => setUnitType(e.target.value)}>
              <option></option>
              <option>Ambulance</option>
              <option>Response</option>
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control as="textarea" placeholder="Unit description" onChange={(e) => setUnitDesc(e.target.value)}></Form.Control>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => saveUnit()}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddApparatusModal;
