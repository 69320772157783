import React from "react";

//import firebase
import { doc, setDoc } from "firebase/firestore";

//import components
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const AddClassModal = ({ classModal, setClassModal, db, getClasses, name, setName, date, setDate, description, setDescription }) => {
  async function uploadClass() {
    await setDoc(doc(db, "classes", name), {
      date: date,
      description: description,
    })
      .then(() => {
        alert("Class added");
        setName("");
        setDate("");
        setDescription([]);
        setClassModal(false);
        getClasses();
      })
      .catch((err) => console.log(err));
  }

  return (
    <Modal show={classModal} onHide={() => setClassModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Class Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="mb-3">
            <Form.Label>Class Name</Form.Label>
            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Date(s)</Form.Label>
            <Form.Control type="text" value={date} onChange={(e) => setDate(e.target.value)}></Form.Control>
            <Form.Text>You can enter a specific date or duration</Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              style={{ height: "200px" }}
              value={description.length > 0 ? description.join("\n") : description}
              onChange={(e) => {
                setDescription(e.target.value.split(/\r?\n/));
              }}></Form.Control>
            <Form.Text>This box support markdown</Form.Text>
          </Form.Group>
        </Form>
        <div className="d-grid">
          <Button onClick={() => uploadClass()}>Add/Update Class</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddClassModal;
