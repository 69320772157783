import React, { useEffect, useState } from "react";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { ref, getStorage, getDownloadURL } from "firebase/storage";
import OperationalOfficer from "../components/Members/OpOfficer";
import AdminOfficer from "../components/Members/AdminOfficer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import "../components/Members/members.css";
import Mickey from "../assets/mickey.webp";

const Members = ({ db }) => {
  const [opOfficers, setOpOfficers] = useState([]);
  const [adminOfficers, setAdminOfficers] = useState([]);
  const [activeMembers, setActiveMembers] = useState([]);
  const [associateMembers, setAssociateMembers] = useState([]);
  const [lifetimeMembers, setLifetimeMembers] = useState([]);
  const [memorialMembers, setMemorialMembers] = useState([]);
  const [juniorMembers, setJuniorMembers] = useState([]);
  const [nonEMSMembers, setNonEMSMembers] = useState([]);

  function sortName(a, b) {
    let aName = a.name.split(" ");
    let bName = b.name.split(" ");
    return aName[aName.length - 1] > bName[bName.length - 1] ? 1 : bName[bName.length - 1] > aName[aName.length - 1] ? -1 : 0;
  }

  const opOfficerArr = ["Chief", "Deputy Chief", "Captain", "Lieutenant", "Training Captain"];
  const adminOfficerArr = ["President", "Vice President", "Secretary", "Treasurer", "Board Member 1", "Board Member 2"];

  useEffect(() => {
    opOfficerArr.forEach(async (officer, index) => {
      await getDoc(doc(db, "officers", officer))
        .then((snap) => {
          return { ...snap.data() };
        })
        .then((officerObj) => {
          const storage = getStorage();
          const storageRef = ref(storage, `officers/${officer}`);

          getDownloadURL(storageRef)
            .then(async (url) => {
              setOpOfficers((opOfficers) => [...opOfficers, { title: officer, url: url, index: index, ...officerObj }]);
              setOpOfficers((opOfficers) => [...opOfficers].sort((a, b) => a.index - b.index));
            })
            .catch(() => {
              setOpOfficers((opOfficers) => [...opOfficers, { title: officer, index: index, ...officerObj }]);
              setOpOfficers((opOfficers) => [...opOfficers].sort((a, b) => a.index - b.index));
            });
        });
    });

    adminOfficerArr.forEach((officer, index) => {
      getDoc(doc(db, "officers", officer))
        .then((snap) => {
          return { ...snap.data() };
        })
        .then((officerObj) => {
          const storage = getStorage();
          const storageRef = ref(storage, `officers/${officer}`);

          getDownloadURL(storageRef)
            .then((url) => {
              setAdminOfficers((adminOfficers) => [...adminOfficers, { title: officer, url: url, ...officerObj, index: index }]);
              setAdminOfficers((adminOfficers) => [...adminOfficers].sort((a, b) => a.index - b.index));
            })
            .catch(() => {
              setAdminOfficers((adminOfficers) => [...adminOfficers, { title: officer, ...officerObj, index: index }]);
              setAdminOfficers((adminOfficers) => [...adminOfficers].sort((a, b) => a.index - b.index));
              console.log(`Could not retrieve ${officer}`);
            });
        });
    });

    // Get members and sort them into the correct array
    var associateArr = [];
    var activeArr = [];
    var lifetimeArr = [];
    var memorialArr = [];
    var juniorArr = [];
    var nonEMS = [];
    getDocs(collection(db, "members"))
      .then((members) => {
        members.forEach((member) => {
          let memberData = member.data();

          switch (memberData.status) {
            case "Associate":
              associateArr.push(memberData);
              break;

            case "Active":
              activeArr.push(memberData);
              break;

            case "Lifetime":
              lifetimeArr.push(memberData);
              break;

            case "Junior":
              juniorArr.push(memberData);
              break;

            case "Non-EMS":
              nonEMS.push(memberData);
              break;

            default:
              memorialArr.push(memberData);
              break;
          }
        });
      })
      .then(() => {
        setAssociateMembers(associateArr.sort(sortName));
        setActiveMembers(activeArr.sort(sortName));
        setLifetimeMembers(lifetimeArr.sort(sortName));
        setMemorialMembers(memorialArr.sort(sortName));
        setJuniorMembers(juniorArr.sort(sortName));
        setNonEMSMembers(nonEMS.sort(sortName));
      });
  }, []);

  return (
    <div className="m-3 mb-5">
      {/* Operational Officers */}
      <h1 className="officer-heading">Operational Officers</h1>
      {opOfficers.map((officer) => {
        return <OperationalOfficer key={officer.title} officerData={officer} />;
      })}

      {/* Administrative Officers */}
      <h1 className="officer-heading">Administrative Officers</h1>
      <Container className="mb-5 admin-officer-container">
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {adminOfficers.map((officer, index) => {
            return (
              <Col key={index} xs={12} sm={6} md={5} lg={3} className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                <AdminOfficer key={officer.title} officerData={officer} />
              </Col>
            );
          })}
        </Row>
      </Container>

      {/* Active Members */}
      <h1 style={{ textAlign: "center" }} className="mt-5">
        Active Members
      </h1>
      <Container style={{ width: "75%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {activeMembers.map((member) => {
            return (
              <Col xs={6} md={4} lg={3} className="mb-3">
                <div className="member-cluster">
                  <p>{member.name}</p>
                  <span>{member.level}</span>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      {/* Associate Members */}
      <h1 style={{ textAlign: "center" }} className="mt-5">
        Associate Members
      </h1>
      <Container style={{ width: "75%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {associateMembers.map((member) => {
            return (
              <Col xs={6} md={4} lg={3} className="mb-3">
                <div className="member-cluster">
                  <p>{member.name}</p>
                  <span>{member.level}</span>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      {/* Junior Members */}
      <h1 style={{ textAlign: "center" }} className="mt-5">
        Junior Members
      </h1>
      <Container style={{ width: "75%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {juniorMembers.map((member) => {
            return (
              <Col xs={6} md={4} lg={3} className="mb-3">
                <div className="member-cluster">
                  <p>{member.name}</p>
                  <span>{member.level}</span>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      {/* Non-EMS */}
      <h1 style={{ textAlign: "center" }} className="mt-5">
        Non-EMS Members
      </h1>
      <Container style={{ width: "75%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {nonEMSMembers.map((member) => {
            return (
              <Col xs={6} md={4} lg={3} className="mb-3">
                <div className="member-cluster">
                  <p>{member.name}</p>
                  <span>{member.level}</span>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      {/* Lifetime Members */}
      <h1 style={{ textAlign: "center" }} className="mt-5">
        Lifetime Members
      </h1>
      <Container style={{ width: "75%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {lifetimeMembers.map((member) => {
            return (
              <Col xs={6} md={4} lg={3} className="mb-3">
                <div className="member-cluster">
                  <p>{member.name}</p>
                  {member.lifetimeStatus && <span>Active</span>}
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      {/* Memorial */}
      <h1 className="memorial-header mt-5 mb-4">
        In Memory Of...
        <br />
        Gone, but not forgotten
      </h1>
      <Container style={{ width: "85%" }}>
        <Row>
          <Col xs={12} md={6} style={{ textAlign: "center" }}>
            <Image src={Mickey} rounded style={{ height: "300px" }} />
            <p>S. Mickey Good (10/31/1933 - 4/9/2007)</p>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <Row>
              <p>
                In March of 1967 Mickey Good was one of a small group of men that founded the Grottoes Rescue Squad. They began with a 1959 Ambulance that they retrieved from a salvage yard for $150.00. In 2007 Mickey Good was still serving the
                Grottoes Rescue Squad as an Active Lifetime Member and a Member of the Board of Directors.
              </p>
              <p>You are loved and missed</p>
            </Row>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              {memorialMembers.map((member) => {
                return (
                  <Col xs={6} md={4} lg={3} className="mb-3">
                    <div className="memorial-cluster">
                      <p>{member.name}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Members;
