import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import "./apparatus.css";

import Card from "react-bootstrap/Card";

const Response = ({ unitData, db }) => {
  const [responseInfo, setResponseInfo] = useState(null);
  const [responsePhoto, setResponsePhoto] = useState(null);

  useEffect(() => {
    getDoc(doc(db, "apparatus", unitData))
      .then((snap) => {
        setResponseInfo({ ...snap.data(), id: unitData });
        console.log({ ...snap.data(), id: unitData });
        return { ...snap.data(), id: unitData };
      })
      .then((data) => {
        const storage = getStorage();
        const storageRef = ref(storage, `apparatus/${data.id}`);

        getDownloadURL(storageRef).then((url) => setResponsePhoto(url));
      });
  }, []);

  return (
    <Card className="response-card pb-3 mb-3">
      <Card.Img className="response-photo" variant="top" src={responsePhoto} fluid />
      <Card.Body>
        <Card.Title>{responseInfo !== null && responseInfo.name}</Card.Title>
        <Card.Text>{responseInfo !== null && responseInfo.desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Response;
